import React from 'react'
import {LocaleLink, localiseObject} from '../helpers/locale'
import {BACK_TO_CASESTUDIES} from '../helpers/messages'
import Img from 'gatsby-image'

export default function CasestudyHero ({client, jobName, description, fluidImage, backButtonUrl}) {

  return (

    <section className="casestudy-hero">
      <div className="casestudy-hero__header">
        <div className="casestudy-hero__title">
          {client}
          {jobName && <sub>{jobName}</sub>}
        </div>
        <div className="casestudy-hero__description">
          {description}
        </div>
      </div>
      <div className="casestudy-hero__image">
        <Img fluid={fluidImage} alt={client}/>
        <div className="casestudy-hero__buttons">
          <LocaleLink className="back-button" to={backButtonUrl}>
            {localiseObject(BACK_TO_CASESTUDIES)}
          </LocaleLink>
        </div>
      </div>
    </section>
  )
}
