import React from 'react'
import Layout from '../templates/layout'
import CasestudyHero from '../components/casestudy-hero'
import PageHelmet from '../components/page-helmet'
import ContentArray from '../components/content-array'
import {localiseObject, LocaleLink} from '../helpers/locale'
import {unblockPortableText} from '../helpers/sanity'
import {SITE_URL} from '../helpers/constants'
import {NEXT_CASESTUDY} from '../helpers/messages'
import {graphql} from 'gatsby'

import '../scss/pages/index.scss'

export const query = graphql`

  query CasestudyPageQuery($id: String) {
    sanityCaseStudyPage(id: { eq: $id }) {
      id
      title {
        en
        fr
      },
      pageSeo {
        title {
          en
          fr
        }
        description {
          en
          fr
        }
      }
      slug {
        current
      }
      jobName
      client
      _rawIntro
      heroImage {
        asset {
          fluid(maxWidth: 1500) {
            ...GatsbySanityImageFluid
          }
          fixed(width: 1200) {
            src
          }
        }
      }
      _rawContent(resolveReferences: {maxDepth: 4})
      content {
        ... on SanityLargeImage {
          _key
          _type
          asset {
            fluid(maxWidth: 1500) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          }
        }
      }
    }
  }
`

export default function CasestudyPage ({pageContext: {nextPath}, data: {sanityCaseStudyPage: {pageSeo, slug, client, jobName, heroImage, _rawIntro, _rawContent, content}}}) {

  return (
    <Layout fixHeader={true} topPadding={70}>
      <PageHelmet
        url={`${SITE_URL}/casestudies/${slug.current}`}
        pageSeo={pageSeo}
        imageUrl={heroImage?.asset?.fixed?.src}
      />
      <CasestudyHero
        client={client}
        jobName={jobName}
        description={unblockPortableText(localiseObject(_rawIntro))}
        backButtonUrl="/casestudies"
        fluidImage={heroImage?.asset?.fluid}
      />
      <ContentArray _rawContent={_rawContent} content={content}/>
      <div className="show-when-scrolled show-when-scrolled--scrolled">
        <section className="information-text information-text--important">
          <div></div>
          <LocaleLink className="next-button" to={nextPath}>{localiseObject(NEXT_CASESTUDY)}</LocaleLink>
        </section>
      </div>
    </Layout>
  )
}
