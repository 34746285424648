import React from 'react'
import Content from '../components/content'

export default function ContentArray({_rawContent, content}) {

  return (
    <>
      {_rawContent && _rawContent.map((rawContent, i) => (
        <Content key={i} content={rawContent._type == 'largeImage' ? content[i] : rawContent} />
      ))}
    </>
  )
}
